import React from 'react';
import ProjectCard from '../../../Shared/ProjectCard/ProjectCard';

const PROJECTS = [
  {
    id: 'polkacity',
    featuredImageSrc: 'polkacity.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/polkacity.webp').default,
    projectDescription:
      'World’s First NFT MultiChain Social Platform and Video Game. Its is an online virtual world where users socialize and show off their NFTs to the other players.',
    projectName: 'polkacity',
  },
  {
    id: 'stacks',
    featuredImageSrc: 'stacks.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/stacks.webp').default,
    projectDescription:
      'Stacks is an open-source network of decentralized apps and smart contracts built on Bitcoin.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Team Augmentation ', label: 'Team Augmentation'},
        {value: 'Rosetta Implementation ', label: 'Rosetta Implementation '},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
  {
    id: 'alexgo',
    featuredImageSrc: 'alexgo.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/alexgo.webp').default,
    projectDescription:
      'ALEX is an open-source DeFi development company built on the Stacks Blockchain and modeled on the world’s financial markets.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Frontent', label: 'Frontent'},
        {value: 'Smart Contracts', label: 'Smart Contracts'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
];

function MetaverseProjectsSection() {
  return (
    <section className="web-dev__our-work-section">
      <div className="connect-web__expertise-heading blockchain-clients">Our Amazing Clients</div>
      <div className="web-dev__our-work-projects-container">
        {PROJECTS.map((project, i) => (
          <div key={project.id} className="web-dev__our-work-project-container">
            <ProjectCard
              {...project}
              featuredImageLoading="lazy"
              projectDescriptionClass="web-dev__our-work-project-description"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default MetaverseProjectsSection;
