import React from 'react';
import MetaverseConnectPageTemplate from '../../../components/Connect/Metaverse';

const subtitles = {
  A: (
    <>
      Our proven track record in product development across varied verticals has enabled us to help
      Startups, Unicorns and Fortune 500s build successful products. We are ready to do the same for
      you and help you build immersive Metaverse experiences for your customers.
    </>
  ),
  B: 'Leverage our expert metaverse developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <MetaverseConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Metaverse Development Services"
      subtitles={subtitles}
      metaTitle="Metaverse Development Services - Tintash"
      metaDescription="At Tintash our Metaverse Development Services bring immersive experiences to life. Partner with us to build your Metaverse experience together!"
      pageviewTitle="Connect/Metaverse/Metaverse.Development"
    />
  );
}
export default index;
